<template>
  <div>
    <b-card class="blog-edit-wrapper" :title="tituloForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- titulo -->
            <b-col md="6" cols="12">
              <b-form-group label-for="titulo">
                <label for="titulo">Titulo <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Titulo"
                  rules="required"
                >
                  <b-form-input
                    id="titulo"
                    v-model="titulo"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Titulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.titulo"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" cols="12">
              <b-form-group label-for="Url">
                <label for="titulo">Url <span class="text-danger">(*)</span></label>
                <b-form-input
                  id="url"
                  disabled
                  v-model="url"
                  placeholder="Url"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label-for="Fecha Publicacion">
                <label >Fecha Publicación <span class="text-danger">(*)</span></label>
                <flat-pickr
                  v-model="published_at"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', locale: 'es' }"
                />
                <small
                  v-for="error in errores.published_at"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="Estado">
                <label >Estado <span class="text-danger">(*)</span></label>
                <v-select
                  id="blog-edit-estado"
                  v-model="estado"
                  label="title"
                  :disabled="!($hascan('entrada.publicar'))"
                  :options="estadoOpciones"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="Tipo Entrada">
                <label >Tipo Entrada <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Tipo Entrada"
                  rules="required"
                >
                  <v-select
                    id="blog-edit-categoria"
                    v-model="tipo_entrada_id"
                    label="title"
                    :options="tipoEntradaOpciones"
                    :clearable="false"
                  >
                    <template v-if="$hascan('tipo_entradas')" #list-header>
                      <li
                        v-b-modal.modal-tipo-entrada
                        @click="accionTipoEntrada = 'registrar'"
                        class="
                          add-new-client-header
                          d-flex
                          align-items-center
                          my-50
                        "
                      >
                        <feather-icon icon="PlusIcon" size="16" />
                        <span class="align-middle ml-25"
                          >Nuevo Tipo Entrada</span
                        >
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.tipo_entrada_id"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Contenido -->
            <b-col cols="12">
              <b-form-group label="" label-for="">
                <b-button
                  v-b-modal.modal-seleccionar-archivo
                  variant="flat-dark"
                  size="sm"
                >
                  <feather-icon icon="ImageIcon" class="mr-50" />
                  <span class="align-left">Medios</span>
                </b-button>
                <b-button
                  v-b-modal.modal-seleccionar-enlace  
                  variant="flat-dark"
                  size="sm"
                >
                  <feather-icon icon="LinkIcon" class="mr-50" />
                  <span class="align-left">Enlaces</span>
                </b-button>
                <validation-provider
                  #default="{ errors }"
                  name="Contenido"
                  rules="required"
                >
                  <!--<ckeditor 
                      ref="editor"
                      :editor="editor" 
                      v-model="contenido" 
                      :config="editorConfig"
                      @ready="onEditorReady"
                      :state="errors.length > 0 ? false:null"
                    >
                    </ckeditor>-->
                  <CkEditorPersonalizado
                    ref="ckeditor"
                    v-model="contenido"
                  ></CkEditorPersonalizado>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.contenido"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" class="mb-2">
              <div class="border rounded p-2">
                <h5 class="text-center"><b>Foto Principal</b></h5>
                <div class="text-center">
                  <b-form-group label="" label-for="">
                    <div class="box-imagen box-imagen1 border rounded">
                      <b-img
                        v-if="imagen"
                        :src="imagen"
                        center
                        fluid
                        thumbnail
                        class="rounded"
                        alt="Card image cap"
                      />
                    </div>
                  </b-form-group>
                  <b-button
                    v-b-modal.modal-seleccionar-imagen
                    variant="flat-dark"
                    size="sm"
                  >
                    <feather-icon icon="ImageIcon" class="mr-50" />
                    <span class="align-middle">Elegir Imagen</span>
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <b-row>
                <!-- Etiquetas -->
                <!--<b-col cols="12">
                <b-form-group
                  label="Etiquetas"
                  label-for="Etiquetas"
                >
                  <v-select
                    id="blog-edit-etiquetas"
                    v-model="etiquetas_id"
                    multiple
                    label="title"
                    :options="etiquetasOpciones"
                  >
                    <template #list-header>
                      <li
                        v-b-modal.modal-etiqueta @click="accionEtiqueta='registrar'"
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Nueva Etiqueta</span>
                      </li>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>-->
                <b-col cols="12">
                  <!-- Descripcion -->
                  <b-form-group label="Descripcion" label-for="descripcion">
                     <CkEditorPersonalizado
                      ref="ckeditorDescripcion"
                      v-model="descripcion"
                    ></CkEditorPersonalizado>
                    <!--<b-form-textarea
                      id="descripcion"
                      placeholder="Descripcion"
                      rows="11"
                      v-model="descripcion"
                    />-->
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- submit -->
          <b-row>
            <b-col cols="12" class="mt-50">
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                split
                text="Guardar"
                variant="primary"
                class="mr-1"
                @click="validar(), (guardarSalir = true)"
                right
              >
                <b-dropdown-item @click="validar(), (guardarSalir = false)">
                  Previsualizar
                </b-dropdown-item>
              </b-dropdown>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :to="{ name: 'entrada' }"
              >
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <TipoEntradaNuevoEditar
        :accion="accionTipoEntrada"
        :item="itemTipoEntrada"
        @cerrarComponente="cerrarComponenteTipoEntrada"
        @actualizarLista="actualizarListaTipoEntrada"
      >
      </TipoEntradaNuevoEditar>
      <!--Componente Medios -->
      <MediosComponent @enviarMedia="recibirMedia"></MediosComponent>
      <!--Componente Imagen -->
      <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
      <EnlacesComponent @enviarUrl="recibirUrl"></EnlacesComponent>
    </b-card>
  </div>
</template>
<script>
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver, localize} from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BImg,
  BFormFile,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import MediosComponent from "@/componentes/MediosComponent.vue";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import CkEditorPersonalizado from "@/componentes/CkEditorPersonalizado.vue";
import TipoEntradaNuevoEditar from "@/views/aplicaciones/configuracion/tipoEntrada/TipoEntradaNuevoEditar.vue";
import EnlacesComponent from "@/componentes/EnlacesComponent.vue";
const Spanish = require('flatpickr/dist/l10n/es');
localize("es", es);
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BImg,
    BFormFile,
    BFormDatepicker,
    flatPickr,
    BDropdown,
    BDropdownItem,
    MediosComponent,
    ImagenComponent,
    CkEditorPersonalizado,
    TipoEntradaNuevoEditar,
    EnlacesComponent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //datos de entrada
      id: null,
      titulo: "",
      url: "",
      descripcion: "",
      contenido: "",
      published_at: null,
      imagen: "",
      estado: { id: 2, title: "Borrador" },
      estadoOpciones: [
        { id: 1, title: "Publicado" },
        { id: 2, title: "Borrador" },
        { id: 3, title: "Papelera" },
      ],
      categoria_id: { title: "General", value: 1 },
      categoriaOpciones: [],
      etiquetas_id: [],
      etiquetasOpciones: [],
      tipo_entrada_id: "",
      tipoEntradaOpciones: [],
      accionTipoEntrada: "",
      itemTipoEntrada: [],
      //fin datos de entrada
      required,
      submitted: false,
      guardarSalir: true,
      errores: [],
      editorOpcion: {
        placeholder: "Ingrese el contenido aqui ...",
      },
      editorConfig: {},
      accionCategoria: "",
      itemCategoria: [],
      accionEtiqueta: "",
      itemEtiqueta: [],
    };
  },

  computed: {
    tituloForm() {
      return this.id ? "Editar Entrada" : "Nueva Entrada";
    },
  },
  watch: {
    $route: function (val) {
      if (val.params.id) {
        this.editarEntrada(this.$route.params.id);
      } else {
        this.limpiar();
      }
    },
  },
  created() {
    //this.listarEtiquetas();
    //this.listarCategorias();
    this.listarTipoEntradas();
    this.date();
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    date() {
      const hoy = new Date();
      var date =
        hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();
      var time = hoy.getHours() + ":" + hoy.getMinutes();
      this.published_at = date + " " + time;
    },
    listarEtiquetas() {
      var url = "/admin/etiquetas";
      axiosIns
        .get(url)
        .then((res) => {
          this.etiquetasOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listarCategorias() {
      var url = "/admin/categorias";
      axiosIns
        .get(url)
        .then((res) => {
          this.categoriaOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listarTipoEntradas() {
      var url = "/admin/tipo-entradas-default";
      axiosIns
        .get(url)
        .then((res) => {
          this.tipoEntradaOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //categoria
    actualizarListaCategoria(item) {
      this.listarCategorias();
      this.categoria_id = { title: item.nombre, value: item.id };
      this.accionCategoria = "";
      this.itemCategoria = [];
    },
    abriComponenteCategoria(item) {
      this.accionCategoria = "actualizar";
      //this.itemCategoria=item;
    },
    cerrarComponenteCategoria() {
      this.accionCategoria = "";
      this.itemCategoria = [];
    },

    //etiqueta
    actualizarListaEtiqueta(item) {
      this.listarEtiquetas();
      this.etiquetas_id.push({ title: item.nombre, value: item.id });
      this.accionEtiqueta = "";
      this.itemEtiqueta = [];
    },
    abriComponenteEtiqueta(item) {
      this.accionEtiqueta = "actualizar";
      //this.itemEtiqueta=item;
    },
    cerrarComponenteEtiqueta() {
      this.accionEtiqueta = "";
      this.itemEtiqueta = [];
    },

    //tipo entrada
    actualizarListaTipoEntrada(item) {
      this.listarTipoEntradas();
      this.tipo_entrada_id = { title: item.nombre, value: item.id };
      this.accionTipoEntrada = "";
      this.itemTipoEntrada = [];
    },
    abriComponenteTipoEntrada(item) {
      this.accionTipoEntrada = "actualizar";
      //this.itemEtiqueta=item;
    },
    cerrarComponenteTipoEntrada() {
      this.accionTipoEntrada = "";
      this.itemTipoEntrada = [];
    },
    recibirUrl(enlace) {
      //console.log(enlace);
      var itemEnlace=enlace;
      
        const content = `<a href="${itemEnlace.url}">${itemEnlace.titulo}</a>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
    },

    //RECIBIR MEDIA DEL COMPONENTE
    recibirMedia(media) {
      if (media.tipo == "IMAGEN") {
        //this.$refs.ckeditor.editorCk
        const content = `<figure class="image"><img src="${media.url}"></figure>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      } else if (media.tipo == "VIDEO") {
        if (media.enlace) {
          const content = `<figure class="media"><oembed url="${media.url}"></oembed></figure>`;
          const viewFragment = this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }else{
          const content =`
            <div class="raw-html-embed" style="text-align:center;">
              <video width="540" controls>
                <source src="${media.url}" type="video/mp4">
              </video>
            </div>`;
          const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
        }
      } else if(media.tipo=='AUDIO'){
        if (media.enlace) {
           const content =` 
            <div class="raw-html-embed" style="text-align:center;">
              <iframe src="${media.url}" width="50%" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>`;
          const viewFragment = this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }else{
          const content =` 
            <div class="raw-html-embed" style="text-align:center;">
              <audio controls="">
                <source src="${media.url}" type="audio/mpeg">
              </audio>
            </div>`;
          const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
        }
      } else if(media.tipo=='DOCUMENTO'){
        const content =` 
          <div class="raw-html-embed" style="text-align:center;">
            <iframe src="${media.url}" width="80%" height="720px"></iframe>
          </div>`;
        const viewFragment =
        this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
      }else{
        const content = `<a href="${media.url}">${media.titulo}</a>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      }
    },
    //RECIBIR IMAGEN DEL COMPONENTE
    recibirImagen(imagen) {
      this.imagen = imagen.url;
    },

    //REGISTRO DE ENTRADA
    async registrar() {
      axiosIns
        .post("/admin/entradas", {
          titulo: this.titulo,
          contenido: this.contenido,
          published_at: this.published_at,
          categoria_id: this.categoria_id.value,
          tipo_entrada_id: this.tipo_entrada_id.value,
          imagen: this.imagen,
          descripcion: this.descripcion,
          estado: this.estado.id,
          etiquetas_id: this.etiquetas_id,
        })
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Entrada de guardo con exito",
            variant: "success",
            solid: false,
          });
          if (this.guardarSalir) {
            this.$router.push({ name: "entrada" });
          } else {
            this.id = res.data;
            this.$router.push({
              name: "entrada-editar",
              params: { id: res.data },
            });
            this.editarEntrada(this.id);
          }
          this.errores = [];
          this.submitted = false;
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    async actualizar() {
      axiosIns
        .put("/admin/entradas/" + this.id, {
          titulo: this.titulo,
          contenido: this.contenido,
          published_at: this.published_at,
          categoria_id: this.categoria_id.value,
          tipo_entrada_id: this.tipo_entrada_id.value,
          imagen: this.imagen,
          descripcion: this.descripcion,
          estado: this.estado.id,
          etiquetas_id: this.etiquetas_id,
        })
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Entrada de guardo con exito",
            variant: "success",
            solid: false,
          });
          if (this.guardarSalir) {
            this.$router.push({ name: "entrada" });
          } else {
            this.editarEntrada(this.id);
          }
          this.errores = [];
          this.submitted = false;
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    editarEntrada(id) {
      this.id = id;
      axiosIns
        .get("/admin/entradas/" + this.id)
        .then((res) => {
          this.titulo = res.data.titulo;
          this.contenido = res.data.contenido;
          this.published_at = res.data.published_at;
          this.categoria_id = {
            title: res.data.categoria.nombre,
            value: res.data.categoria.id,
          };
          this.tipo_entrada_id = {
            title: res.data.tipo_entrada.nombre,
            value: res.data.tipo_entrada.id,
          };
          this.imagen = res.data.imagen;
          this.descripcion = res.data.descripcion;
          this.etiquetas_id = res.data.etiquetas.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
          const valor = this.estadoOpciones.find(
            (estado) => estado.id === res.data.estado
          );
          this.estado = { id: valor.id, title: valor.title };
          this.url = res.data.url;
          const sha=res.data.sha;
          const url_entrada=res.data.tipo_entrada.url;
          if (!this.guardarSalir) {
            window.open(this.mixing.app_url+"publicaciones/"+url_entrada+'/'+this.url+'?preview='+sha);
          }
        })
        .catch((err) => {});
    },
    limpiar() {
      this.date();
      this.id = null;
      this.titulo = "";
      this.contenido = "";
      this.categoria_id = { title: "General", value: 1 };
      this.tipo_entrada_id = "";
      this.imagen = "";
      this.descripcion = "";
      this.estado = { id: 2, title: "Borrador" };
      this.url = "";
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.editarEntrada(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>